export const shapesConfig = [
  // Left Sphere
  { type: "sphere", position: [-9, 0.5, 1], scale: [1, 1, 1], color: "#F5F5F5" },
  // Bottom Sphere
  { type: "sphere", position: [0, -1.75, -0.5], scale: [1.8, 1.8, 1.8], color: "#F5F5F5" },
  // Right Sphere
  { type: "sphere", position: [13, 1.5, -0.8], scale: [0.8, 0.8, 0.8], color: "#F5F5F5" },
  // Left Torus
  { type: "torus", position: [-9, -1.4, 0.3], rotation: [1.5, -0.85, 1.6], scale: [1.7, 1.7, 1.5], color: "#F5F5F5" },
  // Top Torus
  { type: "torus", position: [9, 2.5, -1.5], rotation: [2.679, -0.3, 1.8], scale: [1, 1, 1], color: "#F5F5F5" },
  // Right Torus
  { type: "torus", position: [14, 0, -1.7], rotation: [0.7, 0.3, 1.8], scale: [1.5, 1.5, 1.5], color: "#F5F5F5" },
];
