import { useState } from 'react';
import styles from './WorkScroller.module.css';

import ispSolutions from '../../assets/apps/ispSolutions.webp';
import kyle from '../../assets/apps/kyle.webp';
import juliet from '../../assets/apps/juliet.webp';
import fishback from '../../assets/apps/fishback.webp';
import ca from '../../assets/apps/ca.webp';
import shelteringScholars from '../../assets/apps/shelteringScholars.webp';

const apps = [
    { id: 1, name: 'Sheltering Scholars', image: shelteringScholars, link: 'https://www.shelteringscholars.org/' },
    { id: 2, name: 'Cultural Architecture', image: ca, link: 'https://www.culturalarchitecture.com/' },
    { id: 3, name: 'Fishback Studio', image: fishback, link: 'https://www.fishbackstudio.com/' },
    { id: 4, name: 'Juliet Doherty Official', image: juliet, link: 'https://www.julietdoherty.com/' },
    { id: 5, name: 'Kyle Jennings Music Official', image: kyle, link: 'https://www.kylejenningsmusic.com/' },
    { id: 6, name: 'ISP Solutions', image: ispSolutions, link: 'https://www.ispsolutions.space' },
];

const WorkScroller = () => {
    const [hoveredLinkIndex, setHoveredLinkIndex] = useState(null);

    const handleLinkMouseEnter = (index) => {
        setHoveredLinkIndex(index);
    };

    const handleLinkMouseLeave = () => {
        setHoveredLinkIndex(null);
    };

    return (
        <div className={styles.workScrollerContainer}>
            {/* Project Links */}
            <div className={styles.projectLinksContainer}>
                <ul className={styles.projectLinks}>
                    {apps.map((app, index) => (
                        <li key={app.id} className={styles.projectLinkItem}>
                            <a
                                href={app.link}
                                target='_blank'
                                rel='noreferrer'
                                className={`${styles.projectLink} ${
                                    hoveredLinkIndex !== null && hoveredLinkIndex !== index ? styles.otherLinks : ''
                                }`}
                                onMouseEnter={() => handleLinkMouseEnter(index)}
                                onMouseLeave={handleLinkMouseLeave}
                            >
                                {app.name}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>

            {/* Scrollable Cards */}
            <div className={styles.scrollContainer}>
                <ul className={styles.appRow}>
                    {apps.map((app) => (
                        <li key={app.id} className={styles.appCard}>
                            <a href={app.link} target='_blank' rel='noreferrer'>
                                <img src={app.image} alt={app.name} className={styles.appImage} />
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default WorkScroller;
