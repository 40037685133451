import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { ThemeProvider } from "./contexts/ThemeContext";
import MainMenu from "./components/MainMenu/MainMenu";
import Home from "./pages/Home/Home";
import Relax from "./pages/Relax/Relax";
import About from "./pages/About/About";
import Music from "./pages/Music/Music";
import Yoga from "./pages/Yoga/Yoga";
import Development from "./pages/Development/Development";
import Acting from "./pages/Acting/Acting";
import useTitle from "./hooks/useTitle";
import "normalize.css";
import "./App.css";

// Simple page transition animation
const pageTransition = {
  in: { opacity: 1, transition: { duration: 0.5 } },
  out: { opacity: 0, transition: { duration: 0.5 } },
};

// Route configuration
const routes = [
  { path: "/", element: Home },
  { path: "/about", element: About },
  { path: "/music", element: Music },
  { path: "/relax", element: Relax },
  { path: "/development", element: Development },
  { path: "/yoga", element: Yoga },
  { path: "/acting", element: Acting },
];

function App() {
  return (
    <Router>
      <ThemeProvider>
        <AppContent />
      </ThemeProvider>
    </Router>
  );
}

function AppContent() {
  const location = useLocation();
  useTitle();

  return (
    <>
      <MainMenu />
      {/* AnimatePresence for route transitions */}
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          {routes.map(({ path, element: Element }) => (
            <Route
              key={path}
              path={path}
              element={
                <motion.div
                      initial="out"
                      animate="in"
                      exit="out"
                      variants={pageTransition}
                    >
                      <Element />
                    </motion.div>
              }
            />
          ))}
        </Routes>
      </AnimatePresence>
    </>
  );
}

export default App;
