import Shapes from "./Shapes/Shapes";
import GlassOverlay from "./GlassOverlay/GlassOverlay";
import styles from "./PageBanner.module.css";

const PageBanner = ({ title, subtitle }) => {
  return (
    <div className={styles.pageBanner}>
      <div className={styles.shapesContainer}>
        <Shapes />
      </div>
      <div className={styles.overlayContainer}>
        <GlassOverlay title={title} subtitle={subtitle} />
      </div>
    </div>
  );
};

export default PageBanner;
