import WorkScroller from "../../components/WorkScroller/WorkScroller";
import ContactForm from "../../components/ContactForm/ContactForm";
import Footer from "../../components/Footer/Footer";
import PageBanner from "../../components/PageBanner/PageBanner";
import styles from "./Development.module.css";

const Development = () => {
  const paragraph = `
  Jordan Olguin is a standout full stack developer and creative technologist, seamlessly blending advanced technical mastery with a refined sense of design. Highly adept in the MERN stack (MongoDB, Express.js, React, Node.js), Redux for state management, and Docker for scalable deployments, Jordan engineers applications that are as powerful under the hood as they are visually captivating.

  His artistry shines through the integration of cutting-edge technologies like Framer Motion and Three.js, enabling smooth animations, immersive 3D experiences, and pixel-perfect interfaces. Jordan’s meticulous attention to detail ensures that every UI/UX component he builds is dynamic, responsive, and intuitive—turning functional applications into engaging digital experiences that leave a lasting impression.

  As a technical leader, Jordan excels in architecting modular, scalable systems that drive performance and maintainability. He combines hands-on coding with strategic guidance, empowering teams and delivering results that exceed expectations. Whether it’s designing seamless API integrations, building highly interactive user interfaces, or creating innovative animations, Jordan thrives at the intersection of technical precision and artistic creativity.

  Beyond his technical and design expertise, Jordan brings a collaborative and personable approach to every project, effortlessly translating ambitious client visions into tangible realities. With an eye for innovation and a relentless commitment to excellence, he transforms complex ideas into products that inspire, engage, and deliver real-world value.
`;

  return (
    <>
      <PageBanner
  title="Full Stack Developer & Creative Technologist"
  subtitle="Engineering scalable systems and crafting immersive digital experiences."
/>
      <div className={styles.developmentPage}>
        <section className={styles.paragraphSection}>
          <p className={styles.developmentText}>{paragraph}</p>
        </section>
        <section className={styles.titleSection}>
          <h1 className={styles.developmentTitle}>PROJECTS</h1>
        </section>
        <section className={styles.workScrollerSection}>
          <WorkScroller />
        </section>
        <ContactForm page="development" />
      </div>
      <Footer />
    </>
  );
};

export default Development;
