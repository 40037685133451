const Material = ({ color }) => {
  return (
    <meshPhysicalMaterial
      color={color}
      metalness={2} 
      roughness={0.5} 
      clearcoat={2}
      clearcoatRoughness={0.5} 
      reflectivity={2} 
      emissive={"#FFFFFF"} 
      emissiveIntensity={0.5} 
    />
  );
};

export default Material;
