const Lights = () => {
  return (
    <>
      {/* Ambient Light */}
      <ambientLight intensity={0.6} color="#ffffff" />

      {/* Soft Directional Light */}
      <directionalLight
        position={[5, 5, 5]}
        intensity={0.8}
        castShadow
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
      />
      <directionalLight
        position={[-5, 5, -5]}
        intensity={0.5}
        color="#cccccc"
      />

      {/* Spotlight for highlights */}
      <spotLight
        position={[0, 10, 5]}
        angle={0.3}
        penumbra={0.7}
        intensity={1.5}
        castShadow
        color="#ffffff"
      />

      {/* Soft Point Lights for Subtle Effects */}
      <pointLight position={[10, -5, -10]} intensity={0.5} color="#dcdcdc" />
      <pointLight position={[-10, 5, 10]} intensity={0.4} color="#f5f5f5" />
    </>
  );
};

export default Lights;
