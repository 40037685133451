import styles from "./GlassOverlay.module.css";

const GlassOverlay = ({ title, subtitle }) => (
  <div className={styles.glassBox}>
    <div className={styles.glassTitle}>{title}</div>
    <div className={styles.glassSubtitle}>{subtitle}</div>
  </div>
);

export default GlassOverlay;
