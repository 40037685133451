import { Canvas } from "@react-three/fiber";
import Lights from "../Lights/Lights";
import { shapesConfig } from "./shapesConfig";
import Material from "../Materials/Material";
import { useState, useEffect } from "react";

const Shapes = () => {
  const [adjustedShapesConfig, setAdjustedShapesConfig] = useState(shapesConfig);

  // Dynamically adjust only horizontal positioning (X-axis)
  const updateShapesConfig = () => {
    const baseWidth = 1440;
    const currentWidth = window.innerWidth;
    const scaleFactor = Math.min(currentWidth / baseWidth, 0.75); // Cap scaling at 1

    const updatedConfig = shapesConfig.map((shape) => {
      const newPosition = [...shape.position]; // Clone position array
      newPosition[0] = shape.position[0] * scaleFactor; // Adjust only X-axis
      return { ...shape, position: newPosition };
    });

    setAdjustedShapesConfig(updatedConfig);
  };

  // Initial adjustment and resize listener
  useEffect(() => {
    updateShapesConfig();
    window.addEventListener("resize", updateShapesConfig);
    return () => window.removeEventListener("resize", updateShapesConfig);
  }, []);

  return (
    <Canvas
      shadows
      dpr={[1, 2]}
      resize={{ scroll: false, offsetSize: true }}
      gammaFactor={2.2}
      linear
    >
      <Lights />
      {adjustedShapesConfig.map((shape, index) => {
        const { type, position, rotation, scale = [1, 1, 1], color } = shape;

        return (
          <mesh
            key={index}
            position={position}
            rotation={rotation}
            scale={scale}
          >
            {type === "sphere" && <sphereGeometry args={[0.3, 32, 32]} />}
            {type === "torus" && <torusGeometry args={[0.5, 0.11, 100, 100]} />}
            <Material color={color} />
          </mesh>
        );
      })}
    </Canvas>
  );
};

export default Shapes;
